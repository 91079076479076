import { getAppConfig } from '@services/config';
import { externalHttpService } from '@services/http';
import { CACHE_TAGS, HttpService } from '@services/http/http';

import { NumeriquesReviewInformation } from '@app-types/numeriques';

type PublicationsServiceConfig = {
    url: string;
    id: string;
    secret: string;
    token: string | null;
    tokenExpiry: number | null;
    ttl: number;
};

export class PublicationsService {
    private httpService: HttpService;
    private configuration: PublicationsServiceConfig;

    public constructor(httpService: HttpService, configuration: PublicationsServiceConfig) {
        this.httpService = httpService;
        this.configuration = configuration;
    }

    private async authenticate(): Promise<void> {
        const response = await this.httpService.post<{
            token_type: string;
            expires_in: number;
            access_token: string;
        }>(
            `${this.configuration.url}/token`,
            new URLSearchParams({
                client_id: this.configuration.id,
                client_secret: this.configuration.secret,
                grant_type: 'client_credentials',
            }),
            {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            },
        );
        this.configuration.token = response.access_token;
        this.configuration.tokenExpiry = Date.now() + response.expires_in * 1000;
    }

    public async ensureAuthenticated(): Promise<void> {
        if (
            !this.configuration.token ||
            (this.configuration.tokenExpiry && Date.now() >= this.configuration.tokenExpiry)
        ) {
            await this.authenticate();
        }
    }
    public async getProductsTestsPublications(
        idsPublication: string[],
    ): Promise<NumeriquesReviewInformation['publications']> {
        //TODO: remove next line when server side
        return this.getProductsTestsPublicationsStatic(idsPublication);
        await this.ensureAuthenticated();
        return this.httpService
            .get<NumeriquesReviewInformation>(
                `${this.configuration.url}/api/publications?ids=${idsPublication.join(',')}&types=tests`,
                {},
                {
                    ttl: this.configuration.ttl,
                    tags: [CACHE_TAGS.NUMERIQUES],
                    headers: { Authorization: `Bearer ${this.configuration.token}` },
                },
            )
            .then(({ publications }) => {
                if (publications) {
                    return publications;
                } else {
                    return [];
                }
            })
            .catch(() => []);
    }

    private async getProductsTestsPublicationsStatic(
        idsPublication: string[],
    ): Promise<NumeriquesReviewInformation['publications']> {
        return this.httpService
            .get<NumeriquesReviewInformation>(
                `${this.configuration.url}/partenaire/bouygues-telecom/publications.json`,
                {
                    token: '7e3X37tzqc61W0FLBoS8uQUPcdUD69xJ37QOKOcor83R0peEWeEx1J6rVZDeQQMQ',
                },
                {
                    ttl: this.configuration.ttl,
                    tags: [CACHE_TAGS.NUMERIQUES],
                },
            )
            .then(({ publications }) => {
                if (publications) {
                    return publications.filter((publication) => idsPublication.includes(publication.id.toString()));
                } else {
                    return [];
                }
            })
            .catch(() => []);
    }
}

const config = getAppConfig();

export const publicationsService = new PublicationsService(externalHttpService, {
    url: config.numeriques.url,
    id: config.numeriques.clientId,
    secret: config.numeriques.clientSecret,
    token: null,
    tokenExpiry: null,
    ttl: parseInt(config.cache.ttl.numeriques),
});

//TODO: uncomment the following line when getProductsTestsPublications is used
// publicationsService.ensureAuthenticated();
