import { isServer } from '@helpers/config';

export const getClientCookies = (name: string) => {
    if (isServer()) {
        return undefined;
    } else {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1] ?? '') : r;
        }, '');
    }
};
